import React from 'react';
import PropTypes from 'prop-types';
import {
  useTheme, Box, Chip, Divider, Typography,
} from '@material-ui/core';
import 'react-custom-scroll/dist/customScroll.css';
import smallPreceptorCardStyles from './styles';
import SpecialtyTypeOfPracticeMobileChips from '../../../SpecialtyTypeOfPracticeMobileChips';
import BlockButtonRotationSearchCard from '../BlockButtonRotationSearchCard';

const SmallPreceptorCard = ({
  accountUID, generalLocation, typeOfPractice, availability,
  hours,
  specialty, averageNumberOfPatientsPerDay, credentialsString, handleClick, noOfStudentsPrecepted, price,
  handleClickAddFavorites, addFavoritesLoading, handleClickRemoveFavorites,
  removeFavoritesLoading, statusCanceled, favorite, rotationCanceled,
}) => {
  const classes = smallPreceptorCardStyles();
  const theme = useTheme();

  const getAvailabilityLabelStyle = availabilityLabel => {
    if (availabilityLabel === 'Not Available') return classes.chipNotAvailable;
    if (availabilityLabel.includes('Partially Available')) return classes.chipPartialAvailable;
    return classes.chipAvailable;
  };
  let availabilityLabel = '';
  if (hours) {
    if (availability > 0) {
      if (hours > availability) {
        availabilityLabel = `Partially Available, ${availability} hours`;
      } else {
        availabilityLabel = `Available, ${hours} hours`;
      }
    } else {
      availabilityLabel = 'Not Available';
    }
  }

  return (
    <Box className={classes.block} onClick={handleClick}>
      <Box width="100%" px={1} pb={1}>
        <Typography className={classes.idText}>
          Preceptor ID:
          &nbsp;
          {accountUID}
        </Typography>
        <Typography variant="body1" className={classes.text}>
          {credentialsString}
        </Typography>
      </Box>
      <Divider className={classes.divider} />
      {(availabilityLabel || price) && (
        <>
          <Box width="100%" p={1} display="flex" gridGap={8}>
            {availabilityLabel && (
              <Chip label={availabilityLabel} className={getAvailabilityLabelStyle(availabilityLabel)} />
            )}
            {price && (
              <Chip label={`$${price}`} className={classes.chip} />
            )}
          </Box>
          <Divider className={classes.divider} />
        </>
      )}
      {noOfStudentsPrecepted.value && (
        <>
          <Box className={classes.section}>
            <Typography display="inline" variant="subtitle2" className={classes.label}>
              Students precepted:
            </Typography>
            <Typography display="inline" variant="subtitle2" className={classes.value}>
              {noOfStudentsPrecepted.value}
            </Typography>
          </Box>
        </>
      )}
      {averageNumberOfPatientsPerDay && (
        <Box className={classes.section}>
          <Typography display="inline" variant="subtitle2" className={classes.label}>
            Patients per day:
          </Typography>
          <Typography display="inline" variant="subtitle2" className={classes.value}>
            {averageNumberOfPatientsPerDay.value}
          </Typography>
        </Box>
      )}
      {generalLocation && (
        <Box className={classes.section}>
          <Typography display="inline" variant="subtitle2" className={classes.label}>
            Location:
          </Typography>
          <Typography display="inline" variant="subtitle2" className={classes.value}>
            {generalLocation}
          </Typography>
        </Box>
      )}
      {(generalLocation || averageNumberOfPatientsPerDay || noOfStudentsPrecepted.value) && (
        <Divider className={classes.divider} style={{ marginTop: theme.spacing(1) }} />
      )}
      <SpecialtyTypeOfPracticeMobileChips specialty={specialty} typeOfPractice={typeOfPractice} />
      {handleClickAddFavorites && (
        <BlockButtonRotationSearchCard
          handleProfileClick={handleClick}
          handleClickAddFavorites={handleClickAddFavorites}
          addFavoritesLoading={addFavoritesLoading}
          handleClickRemoveFavorites={handleClickRemoveFavorites}
          removeFavoritesDisabled={rotationCanceled}
          removeFavoritesLoading={removeFavoritesLoading}
          statusFavorite={favorite}
          statusCanceled={statusCanceled}
        />
      )}
    </Box>
  );
};

SmallPreceptorCard.defaultProps = {
  generalLocation: '',
  typeOfPractice: [],
  availability: null,
  noOfStudentsPrecepted: { key: 0, value: null },
  specialty: [],
  hours: '',
  averageNumberOfPatientsPerDay: null,
  price: null,
  handleClickAddFavorites: null,
  addFavoritesLoading: false,
  handleClickRemoveFavorites: null,
  removeFavoritesLoading: false,
  statusCanceled: false,
  favorite: false,
  rotationCanceled: false,
};

SmallPreceptorCard.propTypes = {
  accountUID: PropTypes.string.isRequired,
  generalLocation: PropTypes.string,
  typeOfPractice: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  hours: PropTypes.string,
  availability: PropTypes.number,
  specialty: PropTypes.arrayOf(PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  })),
  averageNumberOfPatientsPerDay: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  credentialsString: PropTypes.string.isRequired,
  handleClick: PropTypes.func.isRequired,
  noOfStudentsPrecepted: PropTypes.shape({
    key: PropTypes.number,
    value: PropTypes.string,
  }),
  price: PropTypes.string,
  handleClickAddFavorites: PropTypes.func,
  addFavoritesLoading: PropTypes.bool,
  handleClickRemoveFavorites: PropTypes.func,
  removeFavoritesLoading: PropTypes.bool,
  statusCanceled: PropTypes.bool,
  favorite: PropTypes.bool,
  rotationCanceled: PropTypes.bool,
};

export default SmallPreceptorCard;
